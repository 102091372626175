import NotesIcon from '@material-ui/icons/Notes';
import { Button, FormWithRedirect, SaveButton, SaveContextProvider, SelectInput, useDataProvider, useNotify, useRefresh } from "react-admin";
import GlobalLoading from "../../../utils/components/GlobalLoading";
import { DriverReferenceInput } from "../../../drivers/components/DriverReferenceInput";
import IconCancel from "@material-ui/icons/Cancel";
import PersonIcon from "@material-ui/icons/Person";
import React, { useState } from "react";
import { Dialog, DialogActions, DialogContent, DialogTitle } from "@material-ui/core";
import { defaultButtonStyle } from "../../../utils/components/styles/ButtonStyles";
import TextInput from "../../../utils/components/TextInput";
import { PostRequest } from "../../../utils/request/PostRequest";
import Typography from "@material-ui/core/Typography";
import { Spacer } from "../../../utils/components/FormElements";

export const AddNotesButton = ( props ) => {
  const { record, style } = props;
  const [loading, setLoading] = useState(false);
  const [showDialog, setShowDialog] = useState(false);
  const dataProvider = useDataProvider();
  const notify = useNotify();

  if (!record) {
    return null;
  }

  const buttonStyles = {
    ...defaultButtonStyle,
    ...style
  }

  const onSave = async ( values ) => {
    setLoading(true );

    await PostRequest(
      `${process.env.REACT_APP_API}${record.id}/avis_note`,
      {
        note: values.avis_note,
        status: values.avis.status
      }
    ).then( ( response ) => {
        notify( 'Notiz hinzugefügt', { type: 'success' } );
        dataProvider.getOne(
          "contracts",
          { id: record.id, }
        ).then(() => {
          setLoading( false )
        })
        setShowDialog( false );
      } ).catch( ( error ) => {
        notify( `Error: ${error.response.data[ "hydra:description" ]}`, { type: "warning" } );
        setLoading( false )
    } )
  }



  return <>
    {loading && <GlobalLoading/>}
    <FormWithRedirect
      resource="contracts"
      save={ onSave }
      render={( { handleSubmitWithRedirect, pristine, saving } ) => {
        return <SaveContextProvider>
          <Dialog fullWidth open={showDialog} onClose={() => setShowDialog( false )} maxWidth={"xs"}>
            <DialogTitle>Notiz hinzufügen</DialogTitle>

            <DialogContent>
              <TextInput multiline={true} source={"avis_note"} label={"Notiz"} rows={5} fullWidth />
              <Spacer />
              <Typography variant={"h6"}>
                Status wechseln
              </Typography>
              <SelectInput
                label={"Statuswechsel"}
                source={"avis.status"}
                choices={[
                  { id: 'pending', name: 'Offen' },
                  { id: 'first_contact', name: 'Erstkontakt' },
                  { id: 'confirmed', name: 'Termin bestätigt' },
                ]}
                initialValue={record.avis?.status}
                fullWidth
              />
            </DialogContent>

            <DialogActions>
              <Button label="ra.action.cancel" onClick={() => setShowDialog( false )}>
                <IconCancel/>
              </Button>
              <SaveButton
                handleSubmitWithRedirect={ handleSubmitWithRedirect }
                pristine={ pristine }
                saving={ loading }
              />
            </DialogActions>
          </Dialog>

          <Button
            style={buttonStyles}
            title={"Notiz hinzufügen"}
            onClick={() => setShowDialog( true )}
            children={<NotesIcon/>}
          />
        </SaveContextProvider>
      }} />
  </>
}
