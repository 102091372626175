import { Datagrid, FunctionField, List, ListContextProvider, ResourceContextProvider, TextField, TopToolbar, useListController } from 'react-admin';
import DefaultPagination from '../../utils/DefaultPagination';

import React from "react";
import { Box, Typography } from "@material-ui/core";
import { ChangeUserButton } from "./components/ChangeUserButton";
import { AddNotesButton } from "./components/AddNotesButton";
import DateField from "../../utils/components/DateField";
import Table from "@material-ui/core/Table";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import { SetContractTypeButton } from "./components/SetContractTypeButton";
import { avisFilter, avisFilterDefaultValues } from "./components/AvisFilter";

const AvisActions = ( props ) => (
  <TopToolbar>
  </TopToolbar>
);

function translateStatus( status ) {
  const statusMap = {
    'pending': 'Offen',
    'first_contact': 'Erstkontakt',
    'confirmed': 'Überführungsdatum bestätigt',
  }

  return statusMap[ status ] || status;
}

const AvisLog = ( { logs } ) => {
  return (
    <div style={{ backgroundColor: 'rgba(0,0,0,0.05)' }}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell width={"25px"}>#</TableCell>
            <TableCell width={"150px"}>Datum</TableCell>
            <TableCell width={"50px"}>Nutzer</TableCell>
            <TableCell width={"100px"}>Alter Status</TableCell>
            <TableCell width={"100px"}>Neuer Status</TableCell>
            <TableCell>Notiz</TableCell>
          </TableRow>
        </TableHead>

        {logs.map( ( log, index ) => {
          return <TableRow>
            <TableCell>{index+1}</TableCell>
            <TableCell>
              <DateField record={log} source={"date.date"} displayTime={true} displaySeconds={true}/>
            </TableCell>
            <TableCell>
              <TextField record={log} source={"user.kuerzel"}/>
            </TableCell>
            <TableCell>
              {translateStatus( log.status?.from )}
            </TableCell>
            <TableCell>
              {translateStatus( log.status?.to )}
            </TableCell>
            <TableCell>
              <Typography style={{ whiteSpace: 'pre-wrap' }} variant={"body2"}>
                {log.note}
              </Typography>
            </TableCell>
          </TableRow>
        } )}
      </Table>
    </div>
  );
}


const AvisList = ( props ) => {
  const perPage = parseInt( process.env.REACT_APP_LIST_ITEMS )

  const listControllerProps = {
    basePath: '/avis_contracts',
    hasCreate: false,
    resource: 'contracts',
    perPage: perPage,
    exporter: false,
    syncWithLocation: true,
    disableSyncWithLocation: false,
    filter: {
      flexContract: true,
      custom__abgeschlossen: false,
      provider__disable_bundles: true
    },
    sort: {
      field: 'datum',
      order: 'ASC'
    },
  };

  const controllerProps = useListController( listControllerProps );

  return (
    <ResourceContextProvider value="contracts">
      <ListContextProvider value={controllerProps}>
        <List
          sort={{ field: 'datum', order: 'ASC' }}
          perPage={perPage}
          pagination={<DefaultPagination perPageArray={[10, 25, 50, 100, 200, 500, 1000]}/>}
          title={"Avisierung"}
          filters={avisFilter}
          filterDefaultValues={avisFilterDefaultValues}
          actions={<AvisActions/>}
          bulkActionButtons={false}
          syncWithLocation
          basePath="/avis_contracts"
          {...controllerProps}
        >
          <Datagrid
            expand={row => <AvisLog logs={row.record.avis.notes}/>}
            isRowExpandable={row => row.avis?.notes?.length > 0}
          >
            <TextField source={"contractClientAddress.kuerzel"} label={"Kunde"} sortBy="auftraggeber.firma"/>
            <TextField source={"auftragsnummer"} label={"Auftragsnummer"}/>
            <TextField source={"licensePlate"} label={"Kennzeichen"}/>

            <FunctionField label={"Ort"} render={record => {
              if( record.contractType === "PICKUP" ) {
                return record.pickupLocation?.ort
              }
              if( record.contractType === "DELIVERY" ) {
                return record.deliveryLocation?.ort
              }
            }}/>

            <TextField source={"driver.kuerzel"} label={"Fahrer"}/>
            <DateField source={"erzeugtam"} label={"Erhalten"}/>
            <FunctionField label={"Bearbeitet"} render={record => {
              if( !record.avis?.notes || record.avis?.notes?.length <= 0 ) {
                return "";
              }

              return <DateField record={record.avis?.notes[0]} source={"date.date"} />
            }}/>

            <FunctionField label={"Avisiert"} render={record => {
              if( !record.avis?.notes || record.avis?.notes?.length <= 0 ) {
                return "";
              }

              for( let i = 0; i < record.avis?.notes?.length; i++ ) {
                if( record.avis?.notes[i].status?.to === "confirmed" ) {
                  return <DateField record={record.avis?.notes[i]} source={"date.date"} />
                }
              }
            }}/>


            <FunctionField label={"Abholung / Zustellung"} sortBy="contractType" render={record => {
              if( record.contractType === "PICKUP" ) {
                return "Abholung"
              }
              if( record.contractType === "DELIVERY" ) {
                return "Zustellung"
              }

              return <SetContractTypeButton record={record}/>
            }}/>

            <FunctionField label={"Datum"} render={record => {
              if( record.contractType === "PICKUP" ) {
                return record.pickupDate
              }
              if( record.contractType === "DELIVERY" ) {
                return record.deliveryDate
              }
            }}/>

            <FunctionField label={"Uhrzeit"} render={record => {
              if( record.contractType === "PICKUP" ) {
                return record.pickupTimeFrom+" - "+record.pickupTimeTo
              }

              if( record.contractType === "DELIVERY" ) {
                return record.deliveryTimeFrom+" - "+record.deliveryTimeTo
              }
            }}/>

            <FunctionField
              sortBy="avis.status"
              label={"Status"}
              render={record => {
                return translateStatus( record.avis?.status ?? 'pending' )
              }}/>

            <TextField source={"avis.user.kuerzel"} label={"Bearbeiter"}/>

            <Box>
              <FunctionField label={"Actions"} render={record => {
                return <Box style={{ display: "flex", justifyContent: "flex-end" }}>
                  <ChangeUserButton record={record}/>
                  <AddNotesButton record={record}/>
                </Box>
              }}/>
            </Box>
          </Datagrid>
        </List>
      </ListContextProvider>
    </ResourceContextProvider>
  );
};


export { AvisList };
