import GlobalLoading from "../../../utils/components/GlobalLoading";
import { AutocompleteInput, Button, FormWithRedirect, SaveButton, SaveContextProvider, useDataProvider, useNotify, useRefresh } from "react-admin";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import CheckIcon from "@material-ui/icons/Check";
import React, { useState } from "react";
import IconCancel from "@material-ui/icons/Cancel";
import { Dialog, DialogActions, DialogContent, DialogTitle } from "@material-ui/core";
import PersonIcon from '@material-ui/icons/Person';
import { DriverReferenceInput } from "../../../drivers/components/DriverReferenceInput";
import { defaultButtonStyle } from "../../../utils/components/styles/ButtonStyles";
import translateDriverLabel from "../../components/TranslateDriverLabel";

export const ChangeUserButton = ( props ) => {
  const { record, style } = props;
  const [loading, setLoading] = useState(false);
  const [showDialog, setShowDialog] = useState(false);
  const dataProvider = useDataProvider();
  const notify = useNotify();
  const refresh = useRefresh()

  const buttonStyles = {
    ...defaultButtonStyle,
    ...style
  }

  if (!record) {
    return null;
  }

  const onSave = async ( values ) => {
    setLoading(true );
    dataProvider
      .update( "contracts", {
        id: record.id,
        data: {
          avis: {
            ...record.avis,
            user: values.avis.user ?? null
          }
        }
      } )
      .then( ( response ) => {
        // success side effects go here
        notify( "Bearbeiter erfolgreich gewechselt", { type: "success" } );
        setLoading( false );
        setShowDialog( false );
      } )
      .catch( ( error ) => {
        // failure side effects go here
        notify( `Error: ${ error.message }`, { type: "warning" } );
        setLoading( false );
      } );
  }



  return <>
    <FormWithRedirect
      resource="contracts"
      save={ onSave }
      render={( { handleSubmitWithRedirect, pristine, saving } ) => {
        return <SaveContextProvider >

          <Dialog fullWidth open={showDialog} onClose={() => setShowDialog( false )} maxWidth={"xs"}>
            <DialogTitle>Bearbeiter wechseln</DialogTitle>

            <DialogContent>
              <DriverReferenceInput
                initialValue={ record?.avis?.user }
                source={"avis.user"}
                label="Bearbeiter"
                filter={{ gruppe: ["1", "2", "3", "4", "7"] }}
              >
                <AutocompleteInput
                  style={{ minWidth: "200px" }}
                  optionText={( driver ) => translateDriverLabel( driver, "Kein Bearbeiter" )}
                  helperText={false}
                />
              </DriverReferenceInput>
            </DialogContent>

            <DialogActions>
              <Button label="ra.action.cancel" onClick={() => setShowDialog( false )}>
                <IconCancel/>
              </Button>
              <SaveButton
                handleSubmitWithRedirect={ handleSubmitWithRedirect }
                pristine={ pristine }
                saving={ loading }
              />
            </DialogActions>
          </Dialog>

          <Button
            style={buttonStyles}
            title={"Bearbeiter wechseln"}
            onClick={() => setShowDialog( true )}
            children={<PersonIcon/>}
          />
        </SaveContextProvider>
    }} />
  </>
}
